import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Results, useResults } from "@usereactify/search"

import { withSearchResults } from "./withSearchResults"
import { SearchPagination } from "../Pagination/SearchPagination"
import { SearchListError } from "../List/SearchListError"
import { Callout, SearchList } from "../List/SearchList"
import { Empty } from "./Empty/Empty"
import { ResultsWrapper } from "./SearchResultsStyles"
import { Loading } from "../List/Loading/Loading"
import { useShopify } from "../../../hooks/useShopify"
import { useApp } from "../../../hooks/useApp"

export const SearchResults = withSearchResults(
  ({
    isCollection,
    collectionHandle,
    collectionsObjForBreadCrumbs,
    currentPageSize,
    sizeOptions,
    handleSelectedSizeChange,
  }): JSX.Element => {
    const { setOutOfStockVariants } = useApp()
    const { results } = useResults()
    const { getProducts } = useShopify()
    const [prices, setPrices] = useState([])
    const includeFields = [
      "handle",
      "images",
      "presentment_price_ranges",
      "product_type",
      "tags",
      "variants",
      "price_min",
      "price_max",
    ]
    const excludeFields = ["link"]
    const handles = useMemo(
      () =>
        results
          ?.map(result => result?.handle)
          ?.filter(handle => handle !== undefined),
      [results],
    )

    useEffect(() => {
      const fetchPrices = async () => {
        return await getProducts({
          handles,
          firstVariants: 20,
          firstImages: 0,
          firstCollections: 0,
          firstMedia: 0,
        }).then(res => {
          const variants = res
            ?.map(product =>
              product?.variants?.map(variant => ({
                id: product?.id,
                priceV2: variant?.priceV2,
                compareAtPriceV2: variant?.compareAtPriceV2,
              })),
            )
            .flat()

          setPrices(variants)

          const allVariants = res.flatMap(product => product.variants || []);

          // filter out variants that are out of stock
          const outOfStockVariants = allVariants
          .filter(variant => !variant.availableForSale)
          .map(variant => variant.id);

          setOutOfStockVariants(prevState => [...outOfStockVariants]);

        })
      }
      fetchPrices()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [results])

    const memoizedRenderResultCardProduct = useCallback(
      props => (
        <SearchList
          {...props}
          prices={prices}
          isCollection={isCollection}
          collectionHandle={collectionHandle}
          collectionsObjForBreadCrumbs={collectionsObjForBreadCrumbs}
          size={currentPageSize}
        />
      ),
      [
        prices,
        isCollection,
        collectionHandle,
        collectionsObjForBreadCrumbs,
        currentPageSize,
      ],
    )

    const memoizedRenderResultCardCallout = useCallback(
      props => <Callout {...props} />,
      [],
    )

    return (
      <ResultsWrapper>
        <Results
          includeFields={includeFields}
          excludeFields={excludeFields}
          pageSize={currentPageSize}
          listClassName="search-results"
          listStyle={{}}
          renderPaginationNumbered={props => (
            <SearchPagination
              {...props}
              currentPageSize={currentPageSize}
              sizeOptions={sizeOptions}
              handleSelectedSizeChange={handleSelectedSizeChange}
            />
          )}
          renderLoading={() => <Loading size={6} notEmpty={false} />}
          renderNoResults={() => <Empty isCollection={isCollection} />}
          renderError={error => <SearchListError error={error} />}
          renderResultCardProduct={memoizedRenderResultCardProduct}
          renderResultCardCallout={memoizedRenderResultCardCallout}
        />
      </ResultsWrapper>
    )
  },
)
